import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import EmailForm from './finish/EmailForm';
import PageTitle from './PageTitle';

import SuccessImg from '../images/success-img.png';
import resultService from '../services/resultService';

import type ProjectType from '../types/ProjectType';

import './FinishPage.scss';

interface Props {
  project: ProjectType;
}

const FinishPage: React.FC<Props> = ({ project }) => {
  const { t } = useTranslation();

  return (
    <div className="finishPage">
      <PageTitle>{t('email_form.meta_title')}</PageTitle>
      <h1 className="appHeading">{t('email_form.page_heading')}</h1>

      <img src={SuccessImg} alt="Zdrowy styl życia" className="finishPage-image" />

      <div className="finishPage-content">
        {project.has_email_form ? <p>{t('email_form.leave_your_email_for_results')}</p> : null}

        {project.summary_text ? (
          <div className="finishPage-summary">{project.summary_text}</div>
        ) : null}

        {project.has_email_form ? (
          <>
            <EmailForm project={project} />
            <p>{t('email_form.you_dont_want_to_leave_email')}</p>
          </>
        ) : null}

        <Link to={`/wynik/${resultService.getResultUUID()}`} className="appButton">
          {t('email_form.show_result')}
        </Link>
      </div>
    </div>
  );
};

export default FinishPage;
