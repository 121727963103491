import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import FeedbackSection from './FeedbackSection';
import Loader from './Loader';
import PageTitle from './PageTitle';

import resultService from '../services/resultService';

import type CategoryFeedbackType from '../types/CategoryFeedbackType';
import type CategoryType from '../types/CategoryType';

import './ResultPage.scss';

const ResultPage: React.FC = () => {
  const [feedbacks, setFeedbacks] = useState<
    Array<{
      category: CategoryType;
      feedback: CategoryFeedbackType;
      points: number;
      result: number;
    }>
  >([]);
  const [myResult, setMyResult] = useState();
  const [totalPoints, setTotalPoints] = useState();
  const [myFeedback, setMyFeedback] = useState();
  const params = useParams();

  const { t, i18n } = useTranslation();

  const resultUUID = params.uuid;
  useEffect(() => {
    resultService.getResult(resultUUID).then((response) => {
      setFeedbacks(response.feedbacks);
      setMyResult(response.my_result);
      setTotalPoints(response.total_points);
      setMyFeedback(response.my_feedback);

      i18n.changeLanguage(response.result.locale);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultUUID]);

  if (myFeedback) {
    return (
      <div className="quizFeedback">
        <PageTitle>{t('quiz_feedback.meta_title')}</PageTitle>

        <FeedbackSection feedback={myFeedback} result={myResult} maxResult={totalPoints} />
        {feedbacks.map((feedback) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <FeedbackSection key={feedback.category.id} {...feedback} maxResult={feedback.points} />
        ))}
      </div>
    );
  }
  return <Loader />;
};

export default ResultPage;
