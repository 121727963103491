const APP_URL = process.env.API_URL || '';

export default {
  appUrl: APP_URL,
  apiUrl: `${APP_URL}/api`,
  questionsList: `${APP_URL}/api/questions`,
  newResult: `${APP_URL}/api/results/new`,
  resultDetails: `${APP_URL}/api/results/details`,
  updateResult: `${APP_URL}/api/results/save`,
  updateResultEmail: `${APP_URL}/api/results/update`,
  projectDetails: `${APP_URL}/api/projects/details`,
};
