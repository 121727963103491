import { useState, useEffect, useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Loader from './Loader';
import PageTitle from './PageTitle';
import FormFooter from './quiz/FormFooter';
import MultipleChoiseQuestion from './quiz/MultipleChoiseQuestion';
import SingleChoiseQuestion from './quiz/SingleChoiseQuestion';

import questionsService from '../services/questionsService';
import resultService from '../services/resultService';

import CategoryType from '../types/CategoryType';
import QuestionType from '../types/QuestionType';

import './QuizForm.scss';

const QuizForm: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState<Array<QuestionType>>([]);
  const [myAnswers, setMyAnswers] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState<QuestionType>();
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const counters = useMemo(() => {
    const values: { [key: number]: { category: CategoryType; count: number } } = {};
    questions.forEach((question) => {
      if (!(question.id in myAnswers)) {
        if (question.category) {
          values[question.category.id] ||= {
            category: question.category,
            count: 0,
          };
          values[question.category.id].count += 1;
        }
      }
    });
    return values;
  }, [myAnswers, questions]);

  const findUnfilledQuestion = useCallback(() => {
    if (loading) {
      return false;
    }
    const selectedQuestion = questions.find((item) => !myAnswers[item.id]);
    if (selectedQuestion === undefined) {
      setCurrentQuestion(undefined);

      resultService.saveAnswers(myAnswers);
      navigate('/wynik');
      return false;
    }
    setCurrentQuestion(selectedQuestion);
    window.scrollTo(0, 0);

    return true;
  }, [myAnswers, loading, navigate, questions]);

  useEffect(() => {
    if (resultService.getResultUUID() === null) {
      navigate('/');
    } else {
      questionsService.getQuestionsList(i18n.language).then((response) => {
        setQuestions(response.questions);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    findUnfilledQuestion();
  }, [findUnfilledQuestion]);

  const handleChangeAnswers = (answers: Array<number>) => {
    setMyAnswers({
      ...myAnswers,
      [currentQuestion.id]: answers,
    });
  };

  if (loading) {
    return <Loader />;
  }

  if (!currentQuestion) {
    return null;
  }

  return (
    <div className="quizForm">
      <PageTitle>{t('quiz_form.meta_title')}</PageTitle>
      <div className="quizForm--wrapper">
        {currentQuestion.category && (
          <h2
            className="quizForm--category"
            style={
              currentQuestion.category.color_code
                ? { color: currentQuestion.category.color_code }
                : {}
            }>
            {currentQuestion.category.name}
          </h2>
        )}

        {currentQuestion.multiple_selection ? (
          <MultipleChoiseQuestion question={currentQuestion} onSubmit={handleChangeAnswers} />
        ) : (
          <SingleChoiseQuestion question={currentQuestion} onSubmit={handleChangeAnswers} />
        )}
      </div>

      <FormFooter
        counters={counters}
        questions={questions.length}
        filledQuestions={Object.keys(myAnswers).length + 1}
      />
    </div>
  );
};

export default QuizForm;
