import { useTranslation } from 'react-i18next';

import type CategoryFeedbackType from '../types/CategoryFeedbackType';

import './FeedbackArticle.scss';

interface Props {
  feedback: CategoryFeedbackType;
}

const FeedbackArticle: React.FC<Props> = ({ feedback }) => {
  const { i18n } = useTranslation();

  const attachmentUrl =
    i18n.language === 'en' ? feedback.attachment_en_url : feedback.attachment_pl_url;

  if ((!attachmentUrl && !feedback.article_url) || !feedback.article_label) {
    return null;
  }
  return (
    <div className="feedbackArticle">
      <a
        href={attachmentUrl || feedback.article_url}
        className="appButton"
        target="_blank"
        rel="noreferrer">
        {feedback.article_label}
      </a>
    </div>
  );
};

export default FeedbackArticle;
