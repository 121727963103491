import { createRoot } from 'react-dom/client';

import Application from '../components/Application';

const DOMReady = function (callback) {
  if (document.readyState === 'interactive' || document.readyState === 'complete') {
    callback();
  } else {
    document.addEventListener('DOMContentLoaded', callback);
  }
};

DOMReady(() => {
  const root = createRoot(document.body.appendChild(document.createElement('div')));
  root.render(<Application />);
});
