import classnames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import FeedbackArticle from './FeedbackArticle';
import FeedbackTips from './FeedbackTips';
import FeedbackVideo from './FeedbackVideo';

import overallImage from '../images/super.png';

import type CategoryFeedbackType from '../types/CategoryFeedbackType';
import type CategoryType from '../types/CategoryType';

import './FeedbackSection.scss';

interface Props {
  category?: CategoryType;
  feedback: CategoryFeedbackType;
  result: number;
  maxResult: number;
}

const FeedbackSection: React.FC<Props> = ({ category, feedback, result, maxResult }) => {
  const { t, i18n } = useTranslation();

  if (!feedback) {
    return null;
  }
  const attachmentUrl =
    i18n.language === 'en' ? feedback.attachment_en_url : feedback.attachment_pl_url;

  function printNumber(value) {
    return Math.round(10 * value) / 10;
  }

  return (
    <div
      className={classnames('feedbackSection', {
        'feedbackSection-general': !category,
      })}>
      <div className="feedbackSection-top">
        {!!category && (
          <h4 className="feedbackSection-category" style={{ backgroundColor: category.color_code }}>
            {category.name}
          </h4>
        )}
        <h3 className="feedbackSection-result">
          {t('quiz_feedback.your_result_is', {
            total: printNumber(maxResult),
            result: printNumber(result),
          })}
        </h3>
      </div>

      <div className="feedbackSection-columns">
        <div
          className="feedbackSection-description"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: feedback.content }}
        />
        <div className="feedbackSection-image">
          {category ? (
            category?.image && <img src={category?.image} alt="" />
          ) : (
            <img src={overallImage} alt="" />
          )}
        </div>
      </div>
      {feedback.tips && <FeedbackTips feedback={feedback} />}
      {feedback.video_url && <FeedbackVideo feedback={feedback} />}
      {(attachmentUrl || feedback.article_url) && <FeedbackArticle feedback={feedback} />}
    </div>
  );
};

export default FeedbackSection;
