interface Props {
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
}

const AppButton: React.FC<Props> = ({ onClick, children, disabled = false }) => {
  return (
    <button type="button" onClick={onClick} className="appButton" disabled={disabled}>
      {children}
    </button>
  );
};
export default AppButton;
