import { postRequest } from './apiClient';
import endpoints from './endpoints';

import type ProjectType from '../types/ProjectType';

interface ProjectResponse {
  project: ProjectType;
}

const getDetails = (language = 'pl'): Promise<ProjectResponse> => {
  return postRequest(endpoints.projectDetails, { lang: language });
};

export default {
  getDetails,
};
