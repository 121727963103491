import { useTranslation } from 'react-i18next';

import CategoryType from '../../types/CategoryType';

import './FormFooter.scss';

interface Props {
  counters: Record<number, { category: CategoryType; count: number }>;
  questions: number;
  filledQuestions: number;
}

const FormFooter: React.FC<Props> = ({ counters, questions, filledQuestions }) => {
  const { t } = useTranslation();
  return (
    <div className="formFooter">
      <div className="formFooter-counters">
        {Object.values(counters).map(({ category, count }) => (
          <div
            key={category.id}
            className="formFooter-counter"
            style={{ backgroundImage: `url(${category.shape_image})` }}>
            {count}
          </div>
        ))}
      </div>
      <span>
        {t('quiz_form.question_number', {
          number: filledQuestions,
          count: questions,
        })}
      </span>
    </div>
  );
};
export default FormFooter;
