import type CategoryFeedbackType from '../types/CategoryFeedbackType';

import './FeedbackVideo.scss';

interface Props {
  feedback: CategoryFeedbackType;
}

const FeedbackVideo: React.FC<Props> = ({ feedback }) => {
  if (!feedback.video_url) {
    return null;
  }
  return (
    <div className="feedbackVideo">
      {feedback.video_heading || feedback.video_description ? (
        <div className="feedbackVideo--text">
          {feedback.video_heading ? (
            <h3 className="page_subheading">{feedback.video_heading}</h3>
          ) : null}

          {feedback.video_description ? <p>{feedback.video_description}</p> : null}
        </div>
      ) : null}
      {feedback.video_poster && (
        <div className="feedbackVideo--image">
          <a href={feedback.video_url} target="_blank" rel="noreferrer">
            <img src={feedback.video_poster} alt={feedback.video_heading} />
          </a>
        </div>
      )}
    </div>
  );
};

export default FeedbackVideo;
