import { postRequest } from './apiClient';
import endpoints from './endpoints';

import type QuestionType from '../types/QuestionType';

interface QuestionsResponse {
  questions: Array<QuestionType>;
}

const getQuestionsList = (language = 'pl'): Promise<QuestionsResponse> => {
  return postRequest(endpoints.questionsList, { lang: language });
};

export default {
  getQuestionsList,
};
