import { postRequest, rawRequest } from './apiClient';
import ApiError from './apiError';
import endpoints from './endpoints';

const createResult = async (language = 'pl') => {
  const response = await rawRequest(endpoints.newResult, { lang: language }, 'POST');

  if (response.ok) {
    const authHeader = response.headers.get('Authorization');
    localStorage.setItem('result-uuid', authHeader);
    return response.json();
  }
  const statusCode = response.status;
  const errorResponse = await response.json();

  return Promise.reject(new ApiError({ statusCode, ...errorResponse }));
};

const getResult = async (uuid: string) => {
  const response = await rawRequest(endpoints.resultDetails, undefined, 'GET', {
    Authorization: uuid,
  });

  if (response.ok) {
    return response.json();
  }
  const statusCode = response.status;
  const errorResponse = await response.json();

  return Promise.reject(new ApiError({ statusCode, ...errorResponse }));
};

const saveAnswers = (answers: Record<number, Array<number>>) => {
  const params = Object.entries(answers).map(([questionId, answerIds]) => ({
    question_id: questionId,
    answer_ids: answerIds,
  }));

  return postRequest(endpoints.updateResult, { answers: params });
};

const updateEmail = (email: string) => {
  return postRequest(endpoints.updateResultEmail, { email });
};

function getResultUUID() {
  return localStorage.getItem('result-uuid');
}

function clearResultUUID() {
  return localStorage.removeItem('result-uuid');
}

export default {
  createResult,
  getResult,
  saveAnswers,
  updateEmail,
  getResultUUID,
  clearResultUUID,
};
