import { useTranslation } from 'react-i18next';

import type CategoryFeedbackType from '../types/CategoryFeedbackType';

import './FeedbackTips.scss';

interface Props {
  feedback: CategoryFeedbackType;
}

const FeedbackTips: React.FC<Props> = ({ feedback }) => {
  const { t } = useTranslation();

  if (!feedback.tips) {
    return null;
  }
  return (
    <div className="feedbackTips">
      <div className="feedbackTips--text">
        <h3 className="page_subheading">{t('quiz_feedback.do_you_want_to_improve_your_score')}</h3>

        <div
          className="feedbackTips--content"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: feedback.tips }}
        />
      </div>
    </div>
  );
};

export default FeedbackTips;
