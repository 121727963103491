import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './i18n';

import FinishPage from './FinishPage';
import Loader from './Loader';
import QuizForm from './QuizForm';
import ResultPage from './ResultPage';
import WelcomePage from './WelcomePage';

import WellbeingPolskaLogo from '../images/wellbeing-logo.png';
import projectsService from '../services/projectsService';

import type ProjectType from '../types/ProjectType';

import 'react-toastify/dist/ReactToastify.css';
import './Application.scss';

const RouterContext: React.FC = () => {
  const [project, setProject] = useState<ProjectType>();
  const { i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const hostName = window.location.hostname;

    projectsService.getDetails(hostName, i18n.language).then((response) => {
      setProject(response.project);

      if (response.project.has_english_version) {
        i18n.changeLanguage('en');
        projectsService.getDetails(hostName, 'en').then((response2) => {
          setProject(response2.project);
        });
      } else {
        i18n.changeLanguage('pl');
      }
    });
  }, [i18n]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location]);

  return (
    <div className="appWrapper">
      <div className="appWrapper--content">
        {(project && (
          <Routes>
            <Route path="/pytanie" element={<QuizForm />} />
            <Route path="/wynik/:uuid" element={<ResultPage />} />
            <Route path="/wynik" element={<FinishPage project={project} />} />
            <Route path="/" element={<WelcomePage project={project} setProject={setProject} />} />
          </Routes>
        )) || <Loader />}
      </div>
      <div className="appWrapper--logos">
        <img src={WellbeingPolskaLogo} alt="AllForBody - programy wellness" />

        {project && project.company_logo && <img src={project.company_logo} alt="Logo" />}
      </div>
      <ToastContainer theme="dark" />
    </div>
  );
};

const Application: React.FC = () => {
  return (
    <BrowserRouter>
      <RouterContext />
    </BrowserRouter>
  );
};

export default Application;
