import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PageTitle from './PageTitle';
import AppButton from './utils/AppButton';

import DashboardImg from '../images/dashboard-img.png';
import projectsService from '../services/projectsService';
import resultService from '../services/resultService';

import type ProjectType from '../types/ProjectType';

import './WelcomePage.scss';

interface Props {
  project: ProjectType;
  setProject: (value: ProjectType) => void;
}

const HomeDashboard: React.FC<Props> = ({ project, setProject }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const startQuiz = (language: string) => {
    const newLanguage = project.has_english_version && language === 'en' ? 'en' : 'pl';
    i18n.changeLanguage(newLanguage);

    if (newLanguage !== i18n.language) {
      const hostName = window.location.hostname;
      projectsService.getDetails(hostName, newLanguage).then((response) => {
        setProject(response.project);
      });
    }

    resultService.createResult(i18n.language).then(() => {
      navigate('/pytanie');
    });
  };

  return (
    <div className="welcomePage">
      <PageTitle>{t('home_dashboard.meta_title')}</PageTitle>
      <h1 className="appHeading">{t('home_dashboard.page_heading')}</h1>
      <div className="welcomePage-image">
        <img src={DashboardImg} alt="Zdrowy styl życia" />
      </div>
      {project.company_name ? (
        <div className="welcomePage-companyName">{project.company_name}</div>
      ) : null}

      {project.company_logo ? (
        <div className="welcomePage-logo">
          <img src={project.company_logo} alt="Logo" />
        </div>
      ) : null}
      <div className="welcomePage-text">
        <p>
          {project.introduction ||
            t('home_dashboard.answer_the_questions_and_learn_more_about_yourself')}
        </p>

        <div className="welcomePage-buttons">
          <AppButton onClick={() => startQuiz('pl')}>Rozpocznij test</AppButton>

          {project.has_english_version && (
            <AppButton onClick={() => startQuiz('en')}>Start test</AppButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeDashboard;
