import './Loader.scss';

const Loader: React.FC = () => {
  return (
    <div className="appLoader">
      <div className="appLoader--logo">
        <div className="appLoader--image">
          <span />
          <span />
        </div>
      </div>
      Ładowanie danych...
    </div>
  );
};

export default Loader;
