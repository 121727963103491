import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  pl: {
    translation: {
      home_dashboard: {
        meta_title: 'Strona główna',
        page_heading: 'Sprawdź czy prowadzisz zdrowy tryb życia',
        start_test: 'Rozpocznij test',
        answer_the_questions_and_learn_more_about_yourself:
          'Odpowiedz na pytania i dowiedz się o sobie więcej',
      },
      quiz_form: {
        meta_title: 'Wypełnij test',
        question_number: 'Pytanie {{number}} z {{count}}',
      },
      email_form: {
        meta_title: 'Sprawdź wynik',
        page_heading: 'Gratulacje, to już wszystkie pytania',
        show_result: 'Zobacz wynik',
        result_in_pdf: 'Chcę wyniki w PDF',
        you_dont_want_to_leave_email: 'Nie chcesz zostawiać swojego adresu?',
        leave_your_email_for_results: 'Zostaw swój e-mail, a wyślemy Ci Twoje wyniki w PDF',
        report_was_sent: 'Raport został wysłany na podany adres e-mail',
      },
      quiz_feedback: {
        meta_title: 'Twój wynik',
        your_result_is: 'Twój wynik to {{result}} z {{total}} punktów',
        do_you_want_to_improve_your_score: 'Chcesz poprawić swój wynik?',
      },
    },
  },
  en: {
    translation: {
      home_dashboard: {
        meta_title: 'Home page',
        page_heading: 'Check if you lead a healthy lifestyle',
        start_test: 'Start quiz',
        answer_the_questions_and_learn_more_about_yourself:
          'Answer the questions and learn more about yourself',
      },
      quiz_form: {
        meta_title: 'Take a quiz',
        question_number: 'Question {{number}} of {{count}}',
      },
      email_form: {
        meta_title: 'Check your results',
        page_heading: "Congratulations, that's all the questions",
        show_result: 'Show your results',
        result_in_pdf: 'I want results in PDF file',
        you_dont_want_to_leave_email: "You don't want to leave your email address?",
        leave_your_email_for_results:
          'Leave your email and we will send you your results in PDF file',
        report_was_sent: 'The report was sent to your e-mail address',
      },
      quiz_feedback: {
        meta_title: 'Your results',
        your_result_is: 'Your score is {{result}} out of {{total}} points',
        do_you_want_to_improve_your_score: 'Do you want to improve your score?',
      },
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'pl',
    debug: false,

    keySeparator: '.', // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
