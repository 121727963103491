import { useState, useEffect } from 'react';

import AnswerType from '../../types/AnswerType';
import QuestionType from '../../types/QuestionType';
import AppButton from '../utils/AppButton';

import './SingleChoiseQuestion.scss';

interface Props {
  question: QuestionType;
  onSubmit: (answers: Array<number>) => void;
}

const MultipleChoiseQuestion: React.FC<Props> = ({ question, onSubmit }) => {
  const [selectedAnswers, setSelectedAnswers] = useState<Array<number>>([]);

  useEffect(() => {
    setSelectedAnswers([]);
  }, [question.id]);

  const handleChange = (answer: AnswerType) => {
    if (selectedAnswers.includes(answer.id)) {
      setSelectedAnswers((prevSelectedAnswers) =>
        prevSelectedAnswers.filter((item) => item !== answer.id),
      );
    } else {
      setSelectedAnswers((prevSelectedAnswers) => [...prevSelectedAnswers, answer.id]);
    }
  };

  return (
    <div className="questionForm multipleChoiseQuestion">
      <h1 className="appHeading">{question.title}</h1>

      <div className="questionForm-answers">
        {question.answers.map((answer) => (
          <div className="questionForm-answer" key={answer.id}>
            <input
              type="checkbox"
              value={answer.id}
              checked={selectedAnswers.includes(answer.id)}
              id={`answer_${answer.id}`}
              onChange={() => handleChange(answer)}
            />
            <label htmlFor={`answer_${answer.id}`}>{answer.text}</label>
          </div>
        ))}
      </div>

      {question.image && (
        <div className="questionForm-image">
          <img src={question.image} alt={question.title} />
        </div>
      )}

      <AppButton onClick={() => onSubmit(selectedAnswers)} disabled={selectedAnswers.length === 0}>
        Dalej
      </AppButton>
    </div>
  );
};
export default MultipleChoiseQuestion;
