import QuestionType from '../../types/QuestionType';

import './SingleChoiseQuestion.scss';

interface Props {
  question: QuestionType;
  onSubmit: (answers: Array<number>) => void;
}

const SingleChoiseQuestion: React.FC<Props> = ({ question, onSubmit }) => {
  return (
    <div className="questionForm singleChoiseQuestion">
      <h1 className="appHeading">{question.title}</h1>

      <div className="questionForm-answers">
        {question.answers.map((answer) => (
          <div className="questionForm-answer" key={answer.id}>
            <input
              type="radio"
              value={answer.id}
              id={`answer_${answer.id}`}
              onChange={() => onSubmit([answer.id])}
            />
            <label htmlFor={`answer_${answer.id}`}>{answer.text}</label>
          </div>
        ))}
      </div>

      {question.image && (
        <div className="questionForm-image">
          <img src={question.image} alt={question.title} />
        </div>
      )}
    </div>
  );
};
export default SingleChoiseQuestion;
