import qs from 'qs';

import ApiError from './apiError';

interface OptionsType {
  method: string;
  headers: Headers;
  body?: FormData | string;
}

async function rawRequest(
  url: string,
  params?: Record<string, unknown> | FormData,
  method = 'GET',
  extraHeaders = {},
): Promise<Response> {
  const token = localStorage.getItem('result-uuid');

  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'WB-App-Host': window.location.hostname,
  };
  if (token) {
    headers.Authorization = token;
  }

  const options: OptionsType = {
    method,
    headers: new Headers({ ...headers, ...extraHeaders }),
  };

  if (params instanceof FormData) {
    options.headers.delete('Content-Type');
    options.body = params;
  } else if (params != null) {
    options.body = JSON.stringify(params);
  }
  return fetch(url, options);
}

async function apiRequest<ResponseType>(
  url: string,
  params?: Record<string, unknown> | FormData,
  method = 'GET',
): Promise<ResponseType> {
  const response = await rawRequest(url, params, method);
  if (response.ok) {
    return response.json();
  }
  const statusCode = response.status;
  const errorResponse = await response.json();

  return Promise.reject(new ApiError({ statusCode, ...errorResponse }));
}

function getRequest<ResponseType>(url: string, params = {}): Promise<ResponseType> {
  return apiRequest(`${url}?${qs.stringify(params)}`);
}

function postRequest<ResponseType>(url: string, params = {}): Promise<ResponseType> {
  return apiRequest(url, params, 'POST');
}

function putRequest<ResponseType>(url: string, params = {}): Promise<ResponseType> {
  return apiRequest(url, params, 'PUT');
}

function deleteRequest<ResponseType>(url: string, params = {}): Promise<ResponseType> {
  return apiRequest(url, params, 'DELETE');
}

export { rawRequest, getRequest, postRequest, putRequest, deleteRequest };
