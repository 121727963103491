import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import type ProjectType from '../../types/ProjectType';

import resultService from '../../services/resultService';

import './EmailForm.scss';

interface Props {
  project: ProjectType;
}

const EmailForm: React.FC<Props> = ({ project }) => {
  const [email, setEmail] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(!project.terms_rule);

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    resultService.getResult(resultService.getResultUUID()).then((response) => {
      setEmail(response.result.email || '');
    });
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    resultService
      .updateEmail(email)
      .then((response) => {
        navigate(`/wynik/${response.result.uuid}`);
        toast(t('email_form.report_was_sent'), { type: 'success' });
      })
      .catch(({ errors }) => {
        toast(JSON.stringify(errors), { type: 'error' });
      });
  };
  return (
    <form onSubmit={handleSubmit} className="emailForm">
      <div className="emailForm-inputs">
        <input type="email" onChange={({ target: { value } }) => setEmail(value)} value={email} />
        <input
          type="submit"
          value={`${t('email_form.result_in_pdf')}`}
          className="appButton"
          disabled={!acceptTerms}
        />
      </div>

      {!!project.terms_rule && (
        <div className="emailForm-termsBlock">
          <label htmlFor="accept_terms">
            <input
              id="accept_terms"
              type="checkbox"
              checked={acceptTerms}
              onChange={(e) => setAcceptTerms(e.target.checked)}
            />
            <span>{project.terms_rule}</span>
          </label>
        </div>
      )}
    </form>
  );
};

export default EmailForm;
